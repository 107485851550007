.network_details_main {
  display: flex;
  flex-direction: column;
  gap: 45px;
  border: 1px solid #a74505;
  background-color: #fff;
  border-radius: 30px;
  padding: 35px;
  width: 100%;
  max-width: 673px;
  margin: 100px auto;
}

.network_details_main h3 {
  color: #000000;
  text-align: center;
  font-size: xx-large;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
}

.network_details_main
  .network_details_main_form
  .network_details_label_form_box
  label {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.network_details_main
  .network_details_main_form
  .network_details_label_form_box
  input {
  margin: 0 !important;
  border-radius: 50px;
  height: 40px;
  background: white;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
}

.network_details_label_form_box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.network_details_button {
    border: 1px solid #A74505;
    color: #000;
    padding: 10px 30px;
    border-radius: 50px;
    min-width: 126.56px;
    margin-top: 20px;
}
.network_details_button:hover {
    background: linear-gradient(90deg, #A74505 5.68%, #CD7F01 102.16%);
    color: #fff;
}
.border_none {
  border: none !important;
  background: transparent !important;
  color: #000000 !important;
}

.flex-center-box {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
}
