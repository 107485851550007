/* SideDrawer.css */
.side-drawer {
    position: fixed;
    top: 0;
    left: -300px; /* Start off-screen */
    width: 250px;
    height: 100vw;
    background: #fff;
    z-index: 100;
    transition: left 0.3s ease-in-out;
  }
  
  .side-drawer.open {
    left: 0; /* Slide in when open */
  }
  
  .side_nav ul {
    list-style: none;
    padding: 0;
  }
  .side_nav ul li:before {
    width: 0;
    height: 0;
     }
  .side_nav ul li {
    padding: 10px 20px;
    font-size: 18px;
    cursor:"pointer";
    margin: 0;
  }
  .side_nav ul li a{
    color: black;
  }
  .close-div{
    margin: 14px auto;
    border-radius: 100%;
    background: #5333FF;
    border: 1px solid #5333FF;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .close-div>svg{
    color:#80CBFF;
  }