.protocol_main {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background: #fff;
    border: 1px solid #A74505;
    border-radius: 30px;
    padding: 35px;
    width: 100%;
    max-width: 1187px;
    margin: 100px auto;
}

.protocol_main h3 {
    color: #000;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
}

.protocol_button {
    background: #fff;
    width: 100%;
    max-width: 208px;
    padding: 10px 0;
    text-align: center;
    border-radius: 15px;
    font-size: x-large;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: white;
}

.border_none {
    border: none !important;
    background: transparent !important;
    color: #000000 !important;
}

.protocol_main p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;

}
.white_color{
    color: #000 !important;
}

.protocol_card {
    width: 100%;
    max-width: 549.7px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 20px 10px;
    background: #fff;
    gap: 10px;
    height: 178px;
}

.radio_div {
    display: flex;
    justify-content: space-between;
    /* align-items: left; */
}
.radio_div>span{
    padding: 0 !important;
}
.content_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    height: 100%;
}

.protocol_card img {
    width: 32px;
}

.protocol_label_form_box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.text_Align_left {
    text-align: left !important;
}

.card_heading {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
}

.learn_more_color {
    color: #A74505 !important;

}
.protocol_hr_line {
    width: 100%;
    height: 1px;
    background-color: #000000;
}
