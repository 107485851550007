.horizontal-card-grid {
  grid-gap: 40px;
  grid-row-gap: 29px;
  display: grid;
  flex-grow: 1;
  gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(239px, 1fr));
  padding: 0 40px;
}
.rollup-horizontal-card-grid {
  grid-gap: 40px;
  grid-row-gap: 29px;
  display: grid;
  flex-grow: 1;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(239px, 1fr));
  padding: 0 40px;
}
.rollup-horizontal-card-grid div{
  margin-bottom: 16px !important;
}
.rollup-horizontal-card-grid .second_div{
  margin-bottom: 0px !important;
}
.align-center-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-briefcase-2 {
  height: 30px;
  width: 30px;
}

.ul-list > li {
  padding-left: 0px;
}

.ul-list {
  list-style: disc;

  margin-left: 18px;
}

.rass-bt-box > div:hover {
  background: linear-gradient(
    179.26deg,
    #a74505 1.15%,
    #cd7f01 99.72%
  ) !important;
}
.rass-bt-box > div:hover h4 {
  color: #fff;
}
.rass-bt-box > div:hover ul li {
  color: #fff;
}

.RI_icon {
  margin-bottom: 0px !important;
  height: 28px;
  width: 28px;
}

/* .protocol-img:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
} */

.slick-dots li button:before {
  font-size: 18px;
}

.slick-dots li.slick-active button:before {
  color: rgba(167, 69, 5, 1);
  font-size: 24px;
}

.slick-slide {
  height: inherit;
  border: 1px solid rgba(167, 69, 5, 1);
  border-radius: 20px;
  margin-bottom: 20px;
}

.slick-slide:hover {
  box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.35);
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-color: transparent;
}

.naas-slide > p {
  text-align: left;
}
