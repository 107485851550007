.Service-Left-Col-Box {
  background: linear-gradient(90deg, #5434ff 0%, #20bcff 80.47%);
  border-radius: 0px 15px 15px 0px;
  display: flex;
  overflow: hidden;
}

.Service-Left-Box {
  display: flex;
  flex-direction: column;
  gap: 150px;
}

.Service-Left-Col-Box > :nth-child(1) {
  padding: 20px 40px;
}

.Service-Right-Box {
  display: flex;
  flex-direction: column;
  gap: 150px;
  margin-top: 150px;
}

.Service-Right-Col-Box {
  background: linear-gradient(90deg, #5434ff 0%, #20bcff 80.47%);
  border-radius: 15px 0px 0px 15px;
  display: flex;
  overflow: hidden;
}
  
.Service-Right-Col-Box  > :nth-child(2) {
    padding: 20px 40px;
} 

.Service-Left-Col-Box > :nth-child(2) {
  width: 100% !important;
  max-width: 176px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
}


.Service-Right-Col-Box > :nth-child(1) {
  width: 100% !important;
  max-width: 176px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


@media (max-width: 767px) {
  .Service-Right-Col-Box > :nth-child(1) {
    display: none;
  }
  .Service-Left-Col-Box > :nth-child(2) {
    display: none;
  }
  .Service-Left-Box {
    gap: 20px;
  }
  .Service-Right-Box {
    gap: 20px;
    margin-top: 20px;
  }
}