  
  .modal-dialog {
    max-width: 635px;
    margin: 1.75rem auto;
  } 
  header.style-4 {
    padding: 0 20px;
    background-color: var(--white);
    width: 100%;
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 3px 3px 30px rgba(32, 35, 56, 0.06);
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    header.style-4 {
      padding: 0px 2%;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    header.style-4 {
      padding: 0px 2%;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    header.style-4 {
      padding: 0px 15px;
      top: 0;
    }
  }
  @media (max-width: 767px) {
    header.style-4 {
      top: 0;
      padding: 0px 10px;
    }
  }
  header.style-4.sticky {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 999;
    background: var(--white);
    box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
    animation: smooth-header 0.65s linear;
  }
  @keyframes smooth-header {
    0% {
      transform: translateY(-30px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  header.style-4.sticky .main-nav > ul li i {
    top: 21px;
  }
  header.style-4.sticky .main-nav > ul li a {
    padding: 10px 0;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    header.style-4 .header-logo {
      padding: 20px 0px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    header.style-4 .header-logo {
      padding: 15px 0px;
    }
  }
  @media (max-width: 991px) {
    header.style-4 .header-logo {
      padding: 15px 0px;
      max-width: 100px;
    }
  }
  /* header.style-4 .header-logo img {
    max-width: 100%;
  } */
  header.style-4 .header-icons-four a {
    margin-right: 25px;
  }
  header.style-4 .header-icons-four a i {
    color: var(--text-secondary);
  }
  header.style-4 .header-icons-four a.cart-icon {
    position: relative;
    display: inline-block;
  }
  header.style-4 .header-icons-four a.cart-icon::before {
    content: "0";
    font-size: 11px;
    min-width: 14px;
    height: 14px;
    line-height: 14px;
    border-radius: 50%;
    background-color: var(--primary-one);
    display: inline-block;
    color: var(--white);
    text-align: center;
    position: absolute;
    top: 3px;
    right: -8px;
  }
  header.style-4 .header-icons-four i {
    font-size: 18px;
    color: var(--text-primary);
    vertical-align: middle;
  }
  header.style-4 .mobile-logo-wrap {
    max-width: 120px;
  }
  header.style-4 .main-nav {
    display: inline-block;
  }
  header.style-4 .main-nav .mobile-menu-logo {
    display: none;
  }
  header.style-4 .main-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  header.style-4 .main-nav ul li {
    display: inline-block;
    position: relative;
    padding: 0 10px;
    margin-bottom: 0px;
  }
  header.style-4 .main-nav ul li a {
    font-size: 14px;
    color: var(--text-primary);
    font-weight: 500;
    display: block;
    text-transform: capitalize;
    padding: 12px 0;
    position: relative;
    font-family: var(--font-work-sans);
    transition: 0.5s ease-out 0s;
    position: relative;
  }
  header.style-4 .main-nav ul li a:hover {
    color: var(--primary-one);
  }
  header.style-4 .main-nav ul li a:hover:after {
    opacity: 1;
    width: 100%;
  }
  header.style-4 .main-nav ul li a::after {
    content: "";
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 0%;
    height: 2px;
    border-radius: 30px;
    display: block;
    background: linear-gradient(90deg, var(--primary-color1), transparent);
    opacity: 0;
    transition: 0.5s ease-out 0s;
  }
  header.style-4 .main-nav ul li a.active {
    color: var(--primary-one);
  }
  .contactbtn {
    width: 206px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* top: 78px;
  left: 1563px; */
    gap: 0px;
    margin-top: 20px;
    text-align: center;
    border-radius: 15px 0px 15px 15px;
    opacity: 0px;
    color: white;
    background-color: #1746b3;
  }
  .contactbtn > a {
    padding: 0px;
  }
  
  header.style-4 .main-nav ul li i {
    width: 30px;
    font-size: 14px;
    text-align: center;
    color: var(--text-primary);
    font-style: normal;
    position: absolute;
    right: -8px;
    top: 31px;
    z-index: 999;
    cursor: pointer;
    display: none;
  }
  header.style-4 .main-nav ul li ul.sub-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    min-width: 215px;
    background: #fff;
    text-align: left;
    transition: 0.55s ease-in-out;
    transform: translateY(20px);
  }
  header.style-4 .main-nav ul li ul.sub-menu > li {
    padding: 0;
    display: block;
    border-bottom: 1px solid rgba(56, 56, 56, 0.5);
    position: relative;
  }
  header.style-4 .main-nav ul li ul.sub-menu > li i {
    position: absolute;
    top: 15px;
    right: 6px;
    display: block;
    color: var(--white);
  }
  header.style-4 .main-nav ul li ul.sub-menu > li a {
    display: block;
    padding: 10px 15px;
    color: #000;
    font-weight: 300;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 1;
    transition: 0.4s ease-out 0s;
    position: relative;
  }
  header.style-4 .main-nav ul li ul.sub-menu > li a::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 0px;
    height: 1px;
    border-radius: 30px;
    display: block;
    background: linear-gradient(90deg, var(--primary-color1), transparent);
    transition: 0.5s ease-in-out;
  }
  header.style-4 .main-nav ul li ul.sub-menu > li a:hover {
    color: var(--primary-one);
  }
  header.style-4 .main-nav ul li ul.sub-menu > li a:hover:after {
    width: 100%;
  }
  header.style-4 .main-nav ul li ul.sub-menu > li a.active {
    color: var(--primary-one);
  }
  header.style-4 .main-nav ul li ul.sub-menu > li .sub-menu {
    left: 215px;
    position: absolute;
    max-width: 230px;
    min-width: 215px;
    background: #0b0f14;
    top: 0;
  }
  @media only screen and (max-width: 1199px) {
    header.style-4 .main-nav ul li ul.sub-menu > li .sub-menu {
      margin-left: 10px;
      position: unset;
      max-width: 230px;
      min-width: 215px;
      background: transparent;
      top: 0;
    }
  }
  header.style-4 .main-nav ul li ul.sub-menu > li .sub-menu li i {
    display: block;
  }
  header.style-4 .main-nav ul li ul.sub-menu > li:last-child {
    border-bottom: none;
  }
  header.style-4 .main-nav ul li:hover > ul.sub-menu {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  header.style-4 .main-nav ul li:first-child {
    padding-left: 0px;
  }
  @media (max-width: 991px) {
    header.style-4 .main-nav ul li:first-child {
      padding-left: 5px;
    }
  }
  header.style-4 .main-nav ul li.menu-item-has-children > i {
    display: block;
  }
  @media only screen and (max-width: 991px) {
    header.style-4 .main-nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 280px;
      padding: 30px 20px !important;
      z-index: 99999;
      height: 100%;
      overflow: auto;
      background: var(--white);
      transform: translateX(-100%);
      transition: transform 0.3s ease-in;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
    }
    header.style-4 .main-nav.show-menu {
      transform: translateX(0);
    }
    header.style-4 .main-nav .mobile-menu-logo {
      text-align: left;
      padding-top: 20px;
      display: block;
      padding-bottom: 8px;
    }
    header.style-4 .main-nav ul {
      float: none;
      text-align: left;
      padding: 35px 10px 10px 0;
    }
    header.style-4 .main-nav ul li {
      display: block;
      position: relative;
      padding: 0 5px;
    }
    header.style-4 .main-nav ul li i {
      display: block;
    }
    header.style-4 .main-nav ul li a {
      padding: 10px 0;
      display: block;
      font-weight: 500;
      font-size: 18px;
    }
    header.style-4 .main-nav ul li ul.sub-menu {
      position: static;
      min-width: 200px;
      background: 0 0;
      border: none;
      opacity: 1;
      visibility: visible;
      box-shadow: none;
      transform: none;
      transition: none;
      display: none;
      margin-top: 0 !important;
      transform: translateY(0px);
    }
    header.style-4 .main-nav ul li ul.sub-menu > li {
      border-bottom: 1px solid transparent;
    }
    header.style-4 .main-nav ul li ul.sub-menu > li a {
      color: var(--text-primary);
      font-size: 15px;
      font-weight: 500;
    }
    header.style-4 .main-nav ul li ul.sub-menu > li a:hover {
      color: var(--primary-color1);
      margin-left: 10px;
    }
    header.style-4 .main-nav ul li ul.sub-menu > li a.active {
      color: var(--primary-color1);
    }
    header.style-4 .main-nav ul li ul.sub-menu > li i {
      color: var(--text-primary);
      right: -13px;
    }
    header.style-4 .main-nav ul li .bi {
      top: 12px;
      font-size: 16px;
    }
    header.style-4 .mobile-menu {
      position: relative;
      top: 2px;
      padding: 0 5px;
      border-radius: 50%;
      display: inline-block;
    }
    header.style-4 .mobile-menu-btn i {
      font-size: 35px;
    }
    header.style-4 .cross-btn {
      display: inline-block !important;
      position: relative;
      width: 30px !important;
      height: 22px !important;
      cursor: pointer;
      border: 3px solid transparent !important;
    }
    header.style-4 .cross-btn span {
      width: 100%;
      height: 2px;
      background: var(--primary-color1);
      display: block;
      position: absolute;
      right: 0;
      transition: 0.3s;
    }
    header.style-4 .cross-btn .cross-top {
      top: 0;
    }
    header.style-4 .cross-btn .cross-middle {
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
    header.style-4 .cross-btn .cross-bottom {
      bottom: 0;
      width: 100%;
    }
    header.style-4 .cross-btn.h-active span.cross-top {
      transform: rotate(45deg);
      top: 50%;
      margin-top: -1px;
    }
    header.style-4 .cross-btn.h-active span.cross-middle {
      transform: translateX(-30px);
      opacity: 0;
    }
    header.style-4 .cross-btn.h-active span.cross-bottom {
      transform: rotate(-45deg);
      bottom: 50%;
      margin-bottom: -1px;
    }
  }

  .menu-close-btn {
    cursor: pointer;
    z-index: 100;
    width: 20px;
    height: 20px;
  }
  .menu-close-btn span{
    font-size: larger;
    font-weight: 600;
  }
  
  
  .main-nav-wrapper {
    transition: 0.7s ease-in;
    position: relative;
    width: 100%;
    z-index: 999;
  }
  .main-nav-wrapper::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--text-secondary);
    z-index: 1;
  }
  .main-nav-wrapper .sidebar-menu-contact {
    gap: 35px;
  }
  .main-nav-wrapper .sidebar-menu-contact a {
    width: 45px;
    text-align: center;
  }
  .main-nav-wrapper .mobile-logo-area {
    padding: 14px 0px;
  }
  .main-nav-wrapper .main-nav-js {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 5% !important;
    z-index: 99;
    height: 100%;
    overflow-y: auto;
    background: #000;
    box-shadow: 4px 3px 20px rgba(16, 33, 34, 0.06);
    transition: 0.7s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(-10vh);
    opacity: 0;
    visibility: hidden;
    gap: 30px;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .main-nav-wrapper .main-nav-js::before {
    content: "Explore Pages.";
    position: absolute;
    top: 45%;
    right: 10%;
    transform: translateY(-50%);
    display: block;
    font-size: 220px;
    font-weight: 700;
    line-height: 1;
    font-family: var(--font-kegina);
    color: var(--text-light-one);
    opacity: 0.1;
    z-index: 1;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: 0;
    height: 300px;
    letter-spacing: 5px;
    text-align: right;
    z-index: -1;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .main-nav-wrapper .main-nav-js::before {
      font-size: 200px;
    }
  }
  @media (max-width: 991px) {
    .main-nav-wrapper .main-nav-js::before {
      display: none;
      visibility: hidden;
    }
  }
  @media (min-width: 576px) and (max-width: 768px) {
    .main-nav-wrapper .main-nav-js {
      min-width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 576px) {
    .main-nav-wrapper .main-nav-js {
      min-width: 100%;
      max-width: 100%;
    }
  }
  .main-nav-wrapper .main-nav-js::-webkit-scrollbar {
    width: 5px;
  }
  .main-nav-wrapper .main-nav-js::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .main-nav-wrapper .main-nav-js::-webkit-scrollbar-thumb {
    background: #999;
  }
  .main-nav-wrapper .main-nav-js::-webkit-scrollbar-thumb:hover {
    background: var(--border-color);
  }
  .main-nav-wrapper .main-nav-js .menu-close-btn {
    position: absolute;
    right: 5%;
    top: 35px;
    opacity: 1;
    cursor: pointer;
    border: 1px solid var(--white);
    padding: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 30px;
    cursor: pointer !important;
    opacity: 0.8;
  }
  .main-nav-wrapper .main-nav-js .menu-close-btn i {
    color: var(--white);
    cursor: pointer !important;
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul {
    float: none;
    text-align: left;
    padding: 80px 0px 80px 0px;
    margin-bottom: 0px;
    width: 100%;
    max-width: 500px;
    margin-left: 0;
    margin-right: auto;
    z-index: 3;
  }
  @media (max-width: 991px) {
    .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul {
      max-width: 100%;
    }
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul {
      padding: 45px 0px 35px 0px;
    }
  }
  @media (max-width: 767px) {
    .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul {
      padding: 40px 0px 30px 0px;
    }
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li {
    display: block;
    position: relative;
    padding: 5px 5px;
    transition: 0.2s;
    transform: translateY(50px) scaleY(0);
    opacity: 0;
    margin-bottom: 20px;
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li:last-child {
    border-bottom: 1px solid transparent;
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li:hover a:before {
    width: 100%;
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li > .bi {
    position: absolute;
    right: 40px;
    top: 8px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    color: var(--white);
  }
  @media (max-width: 767px) {
    .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li > .bi {
      right: 5px;
    }
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li a {
    padding: 4px 0px;
    display: inline-block;
    font-size: 3rem;
    font-weight: 700;
    color: var(--primary-one-light);
    font-family: var(--font-nunito);
    transition: 0.6s ease;
    font-style: normal;
    text-decoration: none;
    transition: 0.55s ease;
    position: relative;
    text-transform: uppercase;
  }
  @supports (-webkit-text-stroke: 1px var(--primary-one-light)) {
    .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li a {
      -webkit-text-stroke: 1px var(--primary-one-light);
      color: transparent;
    }
  }
  @media (max-width: 576px) {
    .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li a {
      font-size: 2.2rem;
    }
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li a::before {
    overflow: hidden;
    position: absolute;
    top: 3px;
    left: 0px;
    width: 0;
    color: var(--white);
    white-space: nowrap;
    content: attr(data-hover);
    transition: 0.8s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li ul.sub-menu {
    position: static;
    min-width: 180px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 10px !important;
    list-style: none;
    padding-left: 10px;
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li ul.sub-menu li {
    opacity: 1;
  }
  .main-nav-wrapper .main-nav-js .sidebar-menu-area > ul > li ul.sub-menu li a {
    font-size: 22px;
    font-weight: 400;
    padding: 7px 5px;
    font-style: normal;
    text-transform: capitalize;
    -webkit-text-stroke: unset;
    -webkit-text-fill-color: unset;
    font-family: var(--font-nunito);
    color: var(--white);
  }
  .main-nav-wrapper .main-nav-js .show-menu {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  @keyframes navLinkFade {
    from {
      opacity: 0;
      transform: translateY(50px) scaleY(0);
    }
    to {
      opacity: 1;
      transform: translateY(0) scaleY(1);
    }
  }
  .main-nav-wrapper .uesr-aera {
    width: 70px;
    height: 70px;
    line-height: 72px;
    border-radius: 50%;
    text-align: center;
    background: var(--primary-color2);
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
  .main-nav-wrapper .uesr-aera .bi {
    font-size: 35px;
    color: var(--text-primary);
  }
  .main-nav-wrapper .mobile-menu {
    position: relative;
    top: 2px;
    padding: 0 5px;
    border-radius: 50%;
    display: inline-block;
  }

  .modal-dialog {
    max-width: 635px;
    margin: 1.75rem auto;
  }
  .mobile-search {
    background: rgba(0, 0, 0, 0.85);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-items: center;
    position: fixed;
    cursor: pointer;
    transform: scale(0.7);
    top: 0;
    left: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: 0.65s ease;
    padding: 35px 100px;
  }
  @media (max-width: 767px) {
    .mobile-search {
      padding: 20px 20px;
    }
  }
  .mobile-search label {
    color: #fff;
    margin-bottom: 20px;
    font-family: var(--font-nunito);
  }
  .mobile-search.slide {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  .mobile-search input,
  .mobile-search textarea {
    border: none;
    border-radius: unset;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 10px 30px 10px 0px;
    width: 100%;
    background: transparent;
    transition: 0.3s ease-in-out;
    color: #fff;
    height: 50px;
    line-height: 45px;
  }
  .mobile-search input:focus,
  .mobile-search textarea:focus {
    border-bottom: 1px solid #fff;
  }
  .mobile-search input::-moz-placeholder,
  .mobile-search textarea::-moz-placeholder {
    color: #999;
    font-weight: 400;
    font-size: 15px;
  }
  .mobile-search input::placeholder,
  .mobile-search textarea::placeholder {
    color: #999;
    font-weight: 400;
    font-size: 15px;
  }
  .mobile-search .search-cross-btn {
    color: #fff;
    cursor: pointer;
    background: rgba(var(--linear-green), 0.6);
    border-radius: 50%;
    height: 40px;
    min-width: 40px;
    line-height: 38px;
    text-align: center;
    line-height: 43px;
    transition: 0.5s ease;
    border: 1px solid rgba(255, 255, 255, 0.45);
  }
  .mobile-search .search-cross-btn.style-two:hover {
    background: var(--primary-one);
    color: #fff;
  }
  .mobile-search .search-cross-btn:hover {
    background: var(--linear-green);
    color: #fff;
  }
  .mobile-search .search-cross-btn i {
    font-size: 18px;
  }

  .container {
    display: flex;
    flex-direction: column;
  }
  .pl-110 {
    padding-left: 110px;
  }
  .pr-110 {
    padding-right: 110px;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .eg-btn {
    text-align: center;
    display: inline-flex;
    text-decoration: none;
    transition: 0.45s ease-in-out;
    text-transform: capitalize;
    cursor: pointer;
    font-family: var(--font-nunito);
  }
  .eg-btn i {
    font-weight: 200;
    vertical-align: middle;
    margin-right: 5px;
  }
    
  .btn--md {
    padding: 10px 35px;
    font-size: 15px;
    font-weight: 700;
  }
  .btn--primary {
    color: var(--white);
    border-radius: 0px;
    position: relative;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    font-family: var(--font-kegina);
    background: transparent;
    transition: 0.5s;
    border-radius: 5px;
    overflow: hidden;
    background: #f33b00;
  }
  .btn--primary::before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #ffa811 0%, #f33b00 100%);
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.5s;
    z-index: -1;
    opacity: 1;
  }
  .btn--primary:hover {
    color: var(--white);
  }
  .btn--primary:hover::before {
    width: 0px;
  }
  .btn--primary.sibling-two {
    background: var(--text-primary);
    position: relative;
  }
  .btn--primary.sibling-two::before {
    content: "";
    width: 100%;
    height: 100%;
    background: var(--primary-one);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0.5s;
    z-index: -1;
    opacity: 1;
  }
  .btn--primary.sibling-two:hover {
    color: var(--white);
  }
  .btn--primary.sibling-two:hover::before {
    width: 0px;
  }
  .header-five-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    justify-content: flex-end;
  }
  .header-phone-four {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
  }
  @media (min-width: 1400px) and (max-width: 1599px) {
    .header-phone-four {
      display: none;
      visibility: hidden;
    }
  }
  @media (max-width: 1399px) {
    .header-phone-four {
      display: none;
      visibility: hidden;
    }
  }
  .header-phone-four .icon {
    width: 50px;
    height: 50px;
    line-height: 52px;
    border-radius: 50%;
    background-color: var(--primary-one);
    text-align: center;
  }
  .header-phone-four .icon i {
    font-size: 26px;
    color: var(--white);
  }
  .header-phone-four .email span {
    display: inline-block;
    font-size: 14px;
    color: var(--text-primary);
    font-weight: 500;
    margin-bottom: 0px;
  }
  .header-phone-four .email h5 {
    color: var(--primary-one);
    font-size: 20px;
  }
  .header-phone-four .email h5 a {
    color: inherit;
  }
  


  .header-phone-four {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
  }
  @media (min-width: 1400px) and (max-width: 1599px) {
    .header-phone-four {
      display: none;
      visibility: hidden;
    }
  }
  @media (max-width: 1399px) {
    .header-phone-four {
      display: none;
      visibility: hidden;
    }
  }
  .header-phone-four .icon {
    width: 50px;
    height: 50px;
    line-height: 52px;
    border-radius: 50%;
    background-color: var(--primary-one);
    text-align: center;
  }
  .header-phone-four .icon i {
    font-size: 26px;
    color: var(--white);
  }
  .header-phone-four .email span {
    display: inline-block;
    font-size: 14px;
    color: var(--text-primary);
    font-weight: 500;
    margin-bottom: 0px;
  }
  .header-phone-four .email h5 {
    color: var(--primary-one);
    font-size: 20px;
  }
  .header-phone-four .email h5 a {
    color: inherit;
  }
  .getstarted-btn{
    background: linear-gradient(90deg, #A74505 0%, #CD7F01 92.83%, #CD7F01 102.68%);
    width: 205px;
    height: 54px;
    border-radius: 10px 10px 10px 10px;
    opacity: 0px;
    /* color: #fff; */
    justify-content: center;
    align-items: center;
  }