#rt_contact {
  padding: 120px 0;
}

.password-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translate(0%, -85%);
  right: 15px;
  cursor: pointer;
}
@media only screen and (max-width: 640px) {
  #rt_contact {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 640px) {
  #rt_contact h2 {
    margin-bottom: 30px;
  }
}
#rt_contact .contact_info {
  margin-bottom: 110px;
  width: 100%;
  float: left;
  text-align: center;
}
#rt_contact .contact_form {
  padding: 35px 70px;
  background: #ffffff;
  /* -webkit-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -moz-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -o-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -ms-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2); */
  /* box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2); */
  /* border-radius: 30px; */
  border: 1px solid #9A572D;
}
@media only screen and (max-width: 990px) {
  #rt_contact .contact_form {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 480px) {
  #rt_contact .contact_form {
    padding: 20px 15px;
  }
}
#rt_contact .contact_form h3 {
  line-height: 35px;
  margin-bottom: 40px;
  margin-top: 0;
  color: #212529;
}
@media only screen and (max-width: 480px) {
  #rt_contact .contact_form h3 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 28px;
  }
}
#rt_contact .contact_form textarea {
  resize: none !important;
}
@media only screen and (max-width: 480px) {
  #rt_contact .contact_form textarea {
    font-size: 16px;
  }
}
@media only screen and (max-width: 480px) {
  #rt_contact .contact_form input[type="text"],
  #rt_contact .contact_form input[type="email"] {
    font-size: 16px;
  }
}
#rt_contact .contact_form .rt_btn_color {
  margin: 0;
}
#rt_contact .contact_form #submit-btn {
  text-align: right;
  padding-left: 45px;
  padding-right: 45px;
  margin-top: 5px;
  position: relative;
}
@media only screen and (max-width: 480px) {
  #rt_contact .contact_form #submit-btn {
    font-size: 15px;
    margin-top: 5px;
    padding: 10px 30px;
  }
}
#rt_contact .contact_form .mt_load {
  display: none;
  position: absolute;
  top: 11px;
  right: -35px;
  margin-left: 14px;
}
#rt_contact .contact_form .mt_load span {
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #111;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: inline-block;
}
#rt_contact .contact_form .mt_load:after {
  border-radius: 50%;
  width: 26px;
  height: 26px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 767px) {
  #rt_contact .contact_info {
    margin-bottom: 45px;
  }
}
@media only screen and (max-width: 767px) {
  #rt_contact .contact_info .location {
    margin-bottom: 20px;
  }
}
#rt_contact .contact_info .contact_heading {
  margin-bottom: 60px;
}
#rt_contact .contact_info .contact_heading span {
  font-size: 16px;
  margin-left: 30px;
  position: relative;
  display: block;
  font-weight: 500;
}
#rt_contact .contact_info .contact_heading span:before {
  background: #444444;
  content: "";
  height: 1px;
  left: -38px;
  position: absolute;
  top: 11px;
  width: 30px;
}
#rt_contact .contact_info .contact_heading h3 {
  margin-top: 0;
  color: #212529;
}
#rt_contact .contact_info .email_sec .row {
  margin-bottom: 15px;
}
#rt_contact .contact_info .email_sec i {
  font-size: 30px;
  background: -webkit-linear-gradient(#5433ff, #20bdff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#rt_contact .contact_info h5 {
  margin-bottom: 5px;
}
#rt_contact .contact_info h2 {
  color: #444444;
  margin-bottom: 10px;
}
#rt_contact .contact_info p {
  color: #666;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
}
#rt_contact .contact_info a {
  color: #666;
  text-decoration: none;
}

.google-login-button {
  background-color: red;
}

.or-text {
  background: #fff;
  padding: 10px;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0px);
  text-transform: uppercase;
  letter-spacing: 2px;
}
.or-text-2  {
  background: #fff;
  padding: 10px;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0px);
  text-transform: uppercase;
  letter-spacing: 2px;
 }
