.blockchain_development_main {
    display: flex;
    flex-direction: column;
    gap: 74px;
    background: #fff;
    border: 1px solid #A74505;
    border-radius: 30px;
    padding: 35px;
    width: 100%;
    max-width: 1049px;
    margin: 100px auto;
}

.blockchain_development_main h3 {
    color: #000;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
}

.blockchain_development_main .blockchain_development_main_form .blockchain_development_label_form_box label {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

}

.blockchain_development_main .blockchain_development_main_form .blockchain_development_label_form_box input {
    border-radius: 50px;
    margin: 0 !important;
    height: 40px;
    background: white;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
}

.blockchain_development_main .blockchain_development_main_form .blockchain_development_label_form_box select {
    border-radius: 50px;
    height: 40px;
    background: white;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
}

.blockchain_development_label_form_box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.blockchain_development_button {
    background: linear-gradient(90deg, #A74505 5.68%, #A74505 68.17%);
    width: 100%;
    max-width: 208px;
    padding: 10px 0;
    text-align: center;
    border-radius: 15px;
    font-size: x-large;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: white;
}