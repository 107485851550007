
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 100px 10px;
    position: sticky;
    top: 0;
    z-index: 100;
    border-bottom: 1px solid #434343;
    background: white;
    backdrop-filter: blur(22px);
}


.logo img {
    width: 100%;
    max-width: 200px;
    margin: 5px 5px 5px 0;
    cursor: pointer;
}

.nav ul {
    list-style: none;
    display: flex;
    gap: 0px;
}
.nav li{
    margin-bottom: 0;
}
.nav li a {
    text-decoration: none;
    color: #000;
    font-size: 17px;
    font-style: normal;
    cursor: "pointer";
    font-weight: 700;
}
.nav ul li:before {
 width: 0;
 height: 0;
  }

.buy-now {
    padding: 13px 38px;
    width: 100%;
    border-radius: 7px;
    background: linear-gradient(90deg, #4974FF 0%, #22BAFF 102.68%);;
    border: none;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.hamburger-css>svg {
    font-size: 47px;
    color: #80CBFF;

}

.menu-box {
    padding: 5px 8px;
    border-radius: 7px;
    border: 1px solid #BFBFBF;
    background: rgba(14, 14, 14, 0.65);
    backdrop-filter: blur(22px);
    position: absolute;
    width: 100%;
    max-width: 200px;
    top: 101px;
    right: auto;
    left: 228px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.select-buttons {
    color: #F6F6F6;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border:none;
    border-radius: 5px;
    border: 0.5px solid #474747;
    background: #0E0E0E;
    padding: 6px;
}
.menu>.MuiMenu-paper{
    border-radius: 7px !important;
    border: 1px solid #BFBFBF !important;
    background: rgba(14, 14, 14, 0.65) !important;
    backdrop-filter: blur(22px) !important;
}
.menu>.MuiMenu-paper{
    color: #F6F6F6 !important;
} 
@media screen and (max-width: 480px) {
    .logo img {
        width: 100%;
        max-width: 140px;
        margin: 10px 5px 5px 0;
    }
}

@media (max-width: 1300px) {
    .nav li {
        font-size: 15px;
    }
}

@media (max-width: 899px) {
    .header {
        padding: 5px 10px;
    }
}

@media (min-width: 1000px) {
    .hamburger-css {
        display: none;

    }
}

@media (max-width: 1000px) {
    .nav {
        display: none;
    }

    .buy-button {
        display: flex;
        align-items: center;
        gap: 10px;
    }

}

@media (max-width: 500px) {
    .buy-now {
       padding: 13px 17px;
        font-size: 11px;

    }

    .hamburger-css>svg {
        font-size: 47px;

    }


}

@media (min-width: 899px) and (max-width: 1200px) {
    .header {
        padding: 5px 27px;
    }
    .nav > ul > li {
        padding-left: 10px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .nav > ul > li {
        padding-left: 20px;
    }
}


