.play-card-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Hero-Play-Pug-Section {
    margin: 0 auto;
    text-align: center;
}

.btm-play-card {
    text-align: center;
    margin: 0 auto;
}

.parent {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.topImg {
    height: 100%;
    min-height: 400px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('/public/images/plug&play.webp');
    background-attachment: fixed;
}
