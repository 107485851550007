.rollsup_main {
    display: flex;
    flex-direction: column;
    gap: 74px;
    background: linear-gradient(180deg, rgba(144, 162, 255, 0.15) 0%, rgba(126, 191, 255, 0.47) 51.5%, rgba(120, 212, 255, 0.54) 100%);
    border: 1px solid #5061FF;
    border-radius: 30px;
    padding: 35px;
    width: 100%;
    max-width: 1049px;
    margin: 100px auto;
}

.rollsup_main h3 {
    color: #FFFFFF;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
}

.rollsup_main .rollsup_main_form .rollsup_label_form_box label {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

}

.rollsup_main .rollsup_main_form .rollsup_label_form_box input {
    border-radius: 50px;
    margin: 0 !important;
    border: none;
    height: 40px;
    background: white;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
}

.rollsup_main .rollsup_main_form .rollsup_label_form_box select {
    border-radius: 50px;
    margin: 0 !important;
    border: none;
    height: 40px;
    background: white;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
}

.rollsup_label_form_box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.rollsup_button {
    background: linear-gradient(90deg, #523BFD 5.68%, #20BDFF 68.17%);
    width: 100%;
    max-width: 208px;
    padding: 10px 0;
    text-align: center;
    border-radius: 15px;
    font-size: x-large;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: white;
}
