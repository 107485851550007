.node_main {
    display: flex;
    flex-direction: column;
    gap: 74px;
    background: #fff;
    border: 1px solid #A74505;
    border-radius: 30px;
    padding: 35px;
    width: 100%;
    max-width: 1500px;
    margin: 100px auto;
}

.node_main h3 {
    color: #000;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
}

.node_main .node_main_form .node_label_form_box label {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

}

.node_label_form_box input {
    margin: 0 !important;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #A84706;
    height: 49.99px;
    background: white;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
}

.node_label_form_box select {
    border-radius: 50px;
    /* border: none; */
    height: 49.99px;
    background: white;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
}

.node_label_form_box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}

.node_button {
    background: linear-gradient(90deg, #523BFD 5.68%, #20BDFF 68.17%);
    width: 100%;
    max-width: 208px;
    padding: 10px 0;
    text-align: center;
    border-radius: 15px;
    font-size: x-large;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: white;
}


.node_card {
    border-radius: 50px;
    padding: 10px 30px;
    background: #fff;
    border: 1px solid #A74505;
}

.node_card p {
    margin: 0;
}

.configuration_second_node_card {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 50px;
    padding: 37px 30px;
    background: #fff;
}

.configuration_inner_label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}

.configuration_form_div {}

.configuration_input_label {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}

.configuration_hr_line {
    width: 100%;
    height: 1px;
    background-color: #000000;
}

.configuration_input {
    border: 1px solid #A84706 !important;
}

.height_54 {
    height: 60px !important;
}

.glacies_div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.glacies_inner_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.glacies_label {
    font-size: 14px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}

.deploy_button {
    border-radius: 12px;
    border: 1px solid #A74505;
    height: 54px;
    width: 100%;
}

.glacies_p {
    font-size: 14px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}