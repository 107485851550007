.ComingSoon-Container {
    background: #00091b;
    color: #fff;
    height: 100vh;
  }
  
  @keyframes fadeIn {
    from {
      top: 20%;
      opacity: 0;
    }
    to {
      top: 100;
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    from {
      top: 20%;
      opacity: 0;
    }
    to {
      top: 100;
      opacity: 1;
    }
  }
  
  .ComingSoon-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    animation: fadeIn 1000ms ease;
    -webkit-animation: fadeIn 1000ms ease;
  }
  
  .ComingSoon-wrapper > h1 {
    font-size: 50px;
    /* font-family: "Poppins", sans-serif; */
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700;
    text-align: center;
  }
  
  .dot {
    color: #4febfe;
  }
  
  .ComingSoon-wrapper > p {
    text-align: center;
    margin: 18px;
    /* font-family: "Muli", sans-serif; */
    font-weight: normal;
  }
  