.deploy_regions_main {
    display: flex;
    flex-direction: column;
    gap: 74px;
    background: #fff;
    border: 1px solid #A74505;
    border-radius: 0px;
    padding: 35px;
    width: 100%;
    max-width: 1187px;
    margin: 100px auto;
}

.deploy_regions_main h3 {
    color: #000;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
}

.deploy_regions_button {
    background: linear-gradient(90deg, #523BFD 5.68%, #20BDFF 68.17%);
    width: 100%;
    max-width: 208px;
    padding: 10px 0;
    text-align: center;
    border-radius: 15px;
    font-size: x-large;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000;
}

.border_none {
    border: none !important;
    background: transparent !important;
    color: #000000 !important;
}

.deploy_regions_main p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;

}

.deploy_region_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deploy_region_card>div {
    display: flex;
    justify-content: space-between;
    gap: 2px;
    align-items: center;
}
.deploy_region_card img{
    width: 32px;
}