.setup_node_main {
  display: flex;
  flex-direction: column;
  gap: 74px;
  background: #fff;
  border: 1px solid #a74505;
  border-radius: 30px;
  padding: 35px;
  width: 100%;
  max-width: 673px;
  margin: 100px auto;
}

.setup_node_main h3 {
  color: #000;
  text-align: center;
  font-size: xx-large;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
}

.setup_node_main .setup_node_main_form .setup_node_label_form_box label {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.setup_node_main .setup_node_main_form .setup_node_label_form_box input {
  margin: 0 !important;
  border-radius: 50px;
  height: 40px;
  background: white;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
}

.setup_node_label_form_box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.setup_node_button {
  background: linear-gradient(90deg, #523bfd 5.68%, #20bdff 68.17%);
  width: 100%;
  max-width: 208px;
  padding: 10px 0;
  text-align: center;
  border-radius: 15px;
  font-size: x-large;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  color: white;
}

.border_none {
  border: none !important;
  background: transparent !important;
  color: #000000 !important;
}

.setup_node_main p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.flex-center-box {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
}
