.configure_main {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background: #fff;
    border: 1px solid #A74505;
    border-radius: 30px;
    padding: 35px;
    width: 100%;
    max-width: 1187px;
    margin: 100px auto;
}

.configure_main h3 {
    color: #000;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
}

.configure_button {
    background: linear-gradient(90deg, #A74505 5.68%, #A74505 68.17%);
    width: 100%;
    max-width: 208px;
    padding: 10px 0;
    text-align: center;
    border-radius: 15px;
    font-size: x-large;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: white;
}

.border_none {
    border: none !important;
    background: transparent !important;
    color: #000000 !important;
}

.configure_main p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;

}

.white_color {
    color: #000 !important;
}

.configure_card {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 20px 10px;
    background: #fff;
    gap: 10px;
}

.configure_radio_div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.configure_radio_div>span {
    padding: 0 !important;
}

.configure_content_div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    height: 100%;
}

.configure_card img {
    width: 32px;
}

.configure_label_form_box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.text_Align_left {
    text-align: left !important;
}

.card_heading {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
}

.learn_more_color {
    color: #A74505 !important;

}

.configure_label_form_box select {
    width: 100%;
    max-width: 549.7px;
    border-radius: 50px;
    border: none;
    height: 40px;
    background: white;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
}
.active_border{
    border: 3px solid #A74505 !important;
}
.width_100{
    width: 100% !important;
    max-width: 366px !important;
}

.diable_card{
    -webkit-filter: contrast(0.5);
    filter: contrast(0.5);
}