* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box;
}
.icon-briefcase {
  height: 28px;
  width: 28px;
}
ol,
ul {
  list-style: none;
}

:focus {
  outline: 0;
}

.clear {
  clear: both;
  line-height: 0;
  font-size: 0;
}

.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  visibility: hidden;
  height: 0;
}
.clearfix:after .test {
  color: red;
}

.clearfix {
  display: inline-block;
}

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

header,
nav,
section,
article,
aside,
footer {
  display: block;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  background-color: #fff;
}

#__next{
  /* display: flex;
  flex-direction: column; 
  gap: 40px; */
}

/* Preloader */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  z-index: 999999999;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/* End Preloader */
.mbYTP_wrapper {
  min-height: 850px !important;
}

h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  margin-bottom: 35px;
  color: #444444;
}

h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 5px;
  color: #444444;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 10px;
  color: #444444;
}

h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  margin-bottom: 10px;
  color: #444444;
}

h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
  margin-bottom: 10px;
  color: #444444;
}

h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
  color: #444444;
}

hr {
  border: 0.5px solid #f1f0f0;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px;
  color: #666666;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 24px;
  color: #666666;
  padding-left: 30px;
  position: relative;
}
/* ul li:before {
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 0;
  margin-top: 1px;
  position: absolute;
  top: 6px;
  width: 10px;
} */

ol {
  margin: 0;
  counter-reset: i;
  position: relative;
}
ol li {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 24px;
  color: #666666;
  padding-left: 30px;
  position: relative;
}
ol li:before {
  content: " ";
  counter-increment: i;
  content: counter(i) ".";
  position: absolute;
  left: 0;
  color: #5433ff;
  margin-top: 1px;
}

a {
  color: #5433ff;
}
a:hover {
  text-decoration: none;
  color: #212529;
  transition: all 0.4s ease;
}

img {
  width: 100%;
}

section {
  position: relative;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea,
select {
  font-size: 14px;
  font-weight: 300;
  background-color: #fff;
  border: 1px solid #a74505;
  border-radius: 50px;
  padding: 10px 25px;
  width: 100%;
  color: #444444;
  margin-bottom: 15px;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
textarea:focus,
select:focus {
  border-color: #5433ff;
  border-color: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  transition: all 0.5s ease;
}

select {
  padding: 12px 25px;
}

textarea {
  border-radius: 8px;
  resize: vertical;
}

blockquote {
  font-size: 14px;
  font-weight: 300;
  background-color: #faf8f8;
  border-left: 4px solid;
  border-left: 4px solid;
  border-color: #5433ff;
  padding: 30px 70px 30px 70px;
  line-height: 24px;
  color: #444444;
  margin-bottom: 20px;
  font-style: italic;
}
@media only screen and (max-width: 767px) {
  blockquote {
    padding: 30px 15px;
  }
}
blockquote span {
  position: relative;
  padding-left: 20px;
}
blockquote span:before {
  content: "";
  width: 12px;
  height: 1px;
  background: #444444;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
}

.mt_heading,
.mt_heading_white {
  margin-bottom: 90px;
}
@media only screen and (max-width: 767px) {
  .mt_heading,
  .mt_heading_white {
    padding: 0 10px;
  }
}
.mt_heading .faded_text,
.mt_heading_white .faded_text {
  position: absolute;
  font-size: 70px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ccc;
  opacity: 0.27;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .mt_heading .faded_text,
  .mt_heading_white .faded_text {
    font-size: 40px;
  }
}
.mt_heading .heading_text,
.mt_heading_white .heading_text {
  position: relative;
  z-index: 1;
}
.mt_heading .section_heading,
.mt_heading_white .section_heading {
  margin-top: 0;
  position: relative;
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 700;
  text-wrap: wrap;
  text-transform: uppercase;
}
@media only screen and (max-width: 360px) {
  .mt_heading .section_heading,
  .mt_heading_white .section_heading {
    font-size: 18px;
  }
}
.mt_heading .section_heading span,
.mt_heading_white .section_heading span {
  display: inline-block;
  padding: 0 0px 15px 0;
}
.mt_heading .heading_txt,
.mt_heading_white .heading_txt {
  margin: 0 auto;
  width: 60%;
}
@media only screen and (max-width: 767px) {
  .mt_heading .heading_txt,
  .mt_heading_white .heading_txt {
    width: 100%;
  }
}

.mt_heading_white .section_heading {
  color: #000000;
}
.mt_heading_white .section_heading span:after {
  background: #fff;
}
.mt_heading_white .section_heading .heading_bg {
  color: #ffffff;
}
.mt_heading_white .heading_txt {
  color: #fff;
}

#general_banner,
#general_banner_main {
  padding: 100px 0;
  position: relative;
  margin-bottom: 0;
  height: 380px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: center !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #general_banner,
  #general_banner_main {
    padding: 150px 0 0;
  }
}
#general_banner .overlay,
#general_banner_main .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#general_banner .bread_crumbs,
#general_banner_main .bread_crumbs {
  text-align: center;
}
#general_banner .bread_crumbs h1,
#general_banner_main .bread_crumbs h1 {
  color: #fff;
  margin-bottom: 5px;
  margin-top: 50px;
}
@media only screen and (max-width: 640px) {
  #general_banner .bread_crumbs h1,
  #general_banner_main .bread_crumbs h1 {
    margin-top: 90px;
  }
}
@media only screen and (max-width: 480px) {
  #general_banner .bread_crumbs h1,
  #general_banner_main .bread_crumbs h1 {
    font-size: 35px;
  }
}
#general_banner .bread_crumbs .breadcrumb,
#general_banner_main .bread_crumbs .breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
}
#general_banner .bread_crumbs .breadcrumb li,
#general_banner_main .bread_crumbs .breadcrumb li {
  padding-right: 0;
  padding-left: 20px;
  position: relative;
}
#general_banner .bread_crumbs .breadcrumb li:first-child,
#general_banner_main .bread_crumbs .breadcrumb li:first-child {
  padding-left: 0;
}
#general_banner .bread_crumbs .breadcrumb li:before,
#general_banner_main .bread_crumbs .breadcrumb li:before {
  display: none;
}
#general_banner .bread_crumbs .breadcrumb li:after,
#general_banner_main .bread_crumbs .breadcrumb li:after {
  content: "\F3D3";
  font-family: "Ionicons";
  color: #ffffff;
  position: absolute;
  right: -14px;
  top: 1px;
}
#general_banner .bread_crumbs .breadcrumb li:last-child:after,
#general_banner_main .bread_crumbs .breadcrumb li:last-child:after {
  display: none;
}
#general_banner .bread_crumbs .breadcrumb li:first-child:before,
#general_banner_main .bread_crumbs .breadcrumb li:first-child:before {
  display: none;
}
#general_banner .bread_crumbs .breadcrumb li a,
#general_banner_main .bread_crumbs .breadcrumb li a {
  color: #fff;
}
#general_banner .bread_crumbs .breadcrumb li a:hover,
#general_banner_main .bread_crumbs .breadcrumb li a:hover {
  color: #ffffff;
}
#general_banner .bread_crumbs .breadcrumb .active,
#general_banner_main .bread_crumbs .breadcrumb .active {
  color: #ffffff;
}

#general_banner_main {
  background: #a74606;
  background: linear-gradient(to right, #a74606 0%, #cd7f01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}

.rt_btn_color,
.rt_btn_white,
input[type="submit"] {
  padding: 10px 30px;
  background: #a74606;
  background: linear-gradient(to right, #a74606 0%, #cd7f01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  font-size: 16px;
  border-radius: 50px;
  margin-bottom: 10px;
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  position: relative;
}
@media only screen and (max-width: 640px) {
  .rt_btn_color,
  .rt_btn_white,
  input[type="submit"] {
    font-size: 14px;
    padding: 10px 19px;
  }
}
.rt_btn_color:hover,
.rt_btn_color:focus,
.rt_btn_white:hover,
.rt_btn_white:focus,
input[type="submit"]:hover,
input[type="submit"]:focus {
  color: #ffffff;
  background: #5433ff;
  background: linear-gradient(to right, #cd7f01 0%, #a74606 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#20BDFF', endColorstr='#5433FF', GradientType=1);
  transition: all 0.5s ease;
  text-shadow: initial;
  text-decoration: none;
}

.rt_btn_white {
  background: #ffffff;
  color: #5433ff;
  font-weight: 600;
  border: 1px solid;
  border-color: #5433ff;
  border-color: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}
.rt_btn_white:hover,
.rt_btn_white:focus {
  color: #f9f9f9;
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  transition: all 0.5s ease;
  text-shadow: initial;
  text-decoration: none;
}

.white-svg,
.gradient-svg,
.grey-svg {
  width: 100%;
  position: absolute;
  bottom: -1px;
  z-index: 10;
}
.white-svg svg,
.gradient-svg svg,
.grey-svg svg {
  width: 100%;
  display: block;
  fill: #fff;
}

.grey-svg svg {
  fill: #f9f9f9;
}

#content {
  margin-bottom: 60px;
}

.color_white {
  color: #fff;
}

.mt_error {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 13px 20px;
  text-align: center;
  background: rgba(228, 25, 25, 0.75);
  color: #fff;
  font-size: 14px;
}

.toast-message {
  font-size: 14px;
}

.mt_load {
  display: none;
  position: absolute;
  top: 11px;
  right: -35px;
  margin-left: 14px;
}
.mt_load span {
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-color: #212529;
  border-left: 2px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: inline-block;
}
.mt_load:after {
  border-radius: 50%;
  width: 26px;
  height: 26px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.circle_1 {
  height: 850px;
  width: 1000px;
  top: 50%;
  left: -310px;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  opacity: 0.1;
  position: absolute;
  transform: translate(0, -50%);
  z-index: -1;
}

.circle_2 {
  height: 850px;
  width: 1000px;
  top: 30%;
  right: -690px;
  border-radius: 60% 80% 10% 100% / 118% 40% 60% 40%;
  opacity: 0.1;
  position: absolute;
  transform: translate(0, -50%);
  z-index: -1;
}

.section_heading_about {
  margin-bottom: 70px;
}
@media only screen and (max-width: 680px) {
  .section_heading_about {
    margin-bottom: 40px;
  }
}
.section_heading_about span {
  font-size: 14px;
  margin-left: 30px;
  position: relative;
  display: block;
  font-weight: 500;
  color: #6e6e6e;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.section_heading_about span:before {
  content: "";
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  height: 1px;
  left: -38px;
  position: absolute;
  top: 50%;
  width: 30px;
}
.section_heading_about h3 {
  margin-top: 5px;
  color: #444;
  font-size: 34px;
  margin-bottom: 0;
  font-weight: 700;
}
@media only screen and (max-width: 680px) {
  .section_heading_about h3 {
    font-size: 25px;
  }
}

/* fancy Button */
.gradient_btn {
  display: inline-block;
  font-size: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  transform: scale(0.95);
}
.gradient_btn span,
.gradient_btn:before {
  background: rgba(167, 69, 5, 1);
  background: linear-gradient(
    90deg,
    #a74505 0%,
    #cd7f01 92.83%,
    #cd7f01 102.68%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}
.gradient_btn:before {
  content: "";
  display: inline-block;
  height: 40px;
  position: absolute;
  bottom: -5px;
  left: 30px;
  right: 30px;
  z-index: -1;
  border-radius: 30em;
  -webkit-filter: blur(20px) brightness(0.95);
  filter: blur(20px) brightness(0.95);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
}
.gradient_btn:focus,
.gradient_btn:active {
  color: #ffffff;
}
.gradient_btn:hover {
  color: #ffffff;
}
.gradient_btn:hover span {
  -webkit-filter: brightness(1.05) contrast(1.05);
  filter: brightness(1.05) contrast(1.05);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.gradient_btn:hover:before {
  bottom: 0;
  -webkit-filter: blur(10px) brightness(0.95);
  filter: blur(10px) brightness(0.95);
}
.gradient_btn span {
  display: inline-block;
  padding: 15px 30px;
  font-size: 14px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  will-change: transform, filter;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
}
.gradient_btn span i {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 10px;
}

.wrapper {
  background-position: 0 100px !important;
}

.shape_3,
.shape_2,
.shape_1 {
  position: absolute;
  z-index: -1;
  height: 191%;
  width: 113%;
  background: #e8f3fd;
  top: -5px;
  left: -44%;
  -webkit-transform: rotate(43deg);
  -ms-transform: rotate(43deg);
  transform: rotate(43deg);
  border-radius: 130px;
  opacity: 0.5;
}
@media only screen and (max-width: 992px) {
  .shape_3,
  .shape_2,
  .shape_1 {
    display: none;
  }
}

.shape_1 {
  right: -90%;
  opacity: 0.3;
  top: -100%;
  left: auto;
  background: #f7f3fc;
}

.shape_3 {
  right: -50%;
  top: 0%;
  left: auto;
  background: #f9f9f9;
}

.two_shapes .shape_2:nth-child(2) {
  top: 6%;
  width: 40% !important;
  opacity: 0.4;
}

.cls-1 {
  fill: #c3c3c3;
  opacity: 0.2;
}

.owl-theme .owl-dots .owl-dot span {
  display: none !important;
}

/* ========================================= */
/* 		   			LAYOUT 					 */
/* ========================================= */
/* ====================== */
/*         Header         */
/* ====================== */
#rt_header {
  z-index: -3;
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li
  a.active {
  border-bottom: 2px solid;
}
#rt_header nav #navigation .menu_btn.active a {
  border: none;
}
#rt_header nav #navigation .gradient_btn {
  line-height: 16px !important;
  color: #5433ff !important;
  margin-left: 30px;
}
@media only screen and (max-width: 767px) {
  #rt_header nav #navigation .gradient_btn {
    margin: 15px 0;
  }
}
#rt_header nav #navigation .gradient_btn span {
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
#rt_header nav #navigation .navbar.navbar-default.reveal-menu-home.sticky-nav {
  padding: 10px;
  background-color: #ffffff;
  transition: background 0.5s ease-in-out 0s, padding 0.5s ease-in-out 0s;
  -webkit-transition: background 0.5s ease-in-out 0s,
    padding 0.5s ease-in-out 0s;
  -moz-transition: background 0.5s ease-in-out 0s, padding 0.5s ease-in-out 0s;
  -o-transition: background 0.5s ease-in-out 0s, padding 0.5s ease-in-out 0s;
  -ms-transition: background 0.5s ease-in-out 0s, padding 0.5s ease-in-out 0s;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.52);
  -o-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.52);
  -ms-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.52);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.52);
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .gradient_btn {
  color: #fff !important;
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .gradient_btn
  span {
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .menu_btn.active
  a {
  border: none !important;
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  ul.nav.navbar-nav
  li
  a:before {
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  ul.nav.navbar-nav
  li.active
  a {
  border-color: #5433ff;
  border-color: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .navbar-collapse
  ul.nav.navbar-nav
  li
  a {
  color: #444444;
}
@media only screen and (max-width: 767px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default.reveal-menu-home.sticky-nav
    .navbar-collapse
    ul.nav.navbar-nav
    li
    a {
    color: #ffffff;
  }
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .logo {
  position: relative;
  float: left;
}
@media only screen and (max-width: 991px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default.reveal-menu-home.sticky-nav
    .logo {
    float: none;
  }
}
@media only screen and (max-width: 767px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default.reveal-menu-home.sticky-nav
    .logo {
    float: left;
  }
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .logo
  .no_sticky_logo {
  visibility: hidden;
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .logo
  .sticky_logo {
  visibility: visible;
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .logo
  img.sticky_logo {
  opacity: 1;
}
@media only screen and (max-width: 991px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default.reveal-menu-home.sticky-nav
    .logo
    img.sticky_logo {
    left: 50%;
    transform: translate(-50%, 0px);
  }
}
@media only screen and (max-width: 767px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default.reveal-menu-home.sticky-nav
    .logo
    img.sticky_logo {
    left: 0;
    transform: initial;
  }
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default.reveal-menu-home.sticky-nav
  .logo
  a
  img {
  margin-top: 2px;
  width: 40px;
}
@media only screen and (max-width: 360px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default.reveal-menu-home.sticky-nav
    .logo
    a
    img {
    margin-top: 10px;
    width: 40px;
  }
}
#rt_header nav #navigation .no_sticky {
  position: absolute;
}
#rt_header nav #navigation .reveal-menu-home {
  background-color: transparent;
}
#rt_header nav #navigation .reveal-menu-home .logo a img {
  width: 40px;
}
@media only screen and (max-width: 360px) {
  #rt_header nav #navigation .reveal-menu-home .logo a img {
    margin-top: 10px;
    width: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #rt_header nav #navigation .reveal-menu-home .navbar-right {
    float: none;
    margin: 0 auto;
    width: 97%;
  }
}
#rt_header nav #navigation .navbar.navbar-default {
  padding: 25px 0;
  border-bottom: 0;
  z-index: 3 !important;
}
#rt_header nav #navigation .navbar.navbar-default .logo .sticky_logo {
  visibility: hidden;
}
#rt_header nav #navigation .navbar.navbar-default .logo img.sticky_logo {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}
@media only screen and (max-width: 767px) {
  #rt_header nav #navigation .navbar.navbar-default .navbar-toggle {
    border-color: #212529;
    background-color: #212529;
  }
  #rt_header nav #navigation .navbar.navbar-default .navbar-toggle:hover {
    border-color: #ffffff;
    background-color: #ffffff;
  }
  #rt_header
    nav
    #navigation
    .navbar.navbar-default
    .navbar-toggle:hover
    .icon-bar {
    background-color: #212529;
  }
}
@media only screen and (max-width: 767px) {
  #rt_header nav #navigation .navbar.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #rt_header nav #navigation .navbar.navbar-default .navbar-header {
    float: none;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  #rt_header nav #navigation .navbar.navbar-default .navbar-collapse {
    background: #212529;
    border: none;
    margin-top: 15px;
    max-height: 415px;
  }
}
@media only screen and (max-width: 640px) {
  #rt_header nav #navigation .navbar.navbar-default .navbar-collapse {
    max-height: 225px;
  }
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li {
  padding: 0;
  margin-bottom: 0;
  margin-left: 20px;
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default
    .navbar-collapse
    ul.nav.navbar-nav
    li {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default
    .navbar-collapse
    ul.nav.navbar-nav
    li {
    margin-bottom: 0;
    margin-left: 12px;
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default
    .navbar-collapse
    ul.nav.navbar-nav
    li {
    margin: 0 15px;
    border-bottom: 1px solid #ffffff;
  }
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li:before {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default
    .navbar-collapse
    ul.nav.navbar-nav
    li:first-child {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default
    .navbar-collapse
    ul.nav.navbar-nav
    li:last-child {
    border-bottom: none;
  }
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li
  a {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  padding: 0;
  line-height: 43px;
  position: relative;
  letter-spacing: 1px;
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default
    .navbar-collapse
    ul.nav.navbar-nav
    li
    a {
    font-size: 13px;
  }
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li
  a:focus:before,
#rt_header
  nav
  #navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li
  a:hover:before {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -o-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform-origin: left top 0;
  -webkit-transform-origin: left top 0;
  -moz-transform-origin: left top 0;
  -o-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li
  a:before {
  background: #ffffff;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 43px;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -o-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform-origin: right top 0;
  -webkit-transform-origin: right top 0;
  -moz-transform-origin: right top 0;
  -o-transform-origin: right top 0;
  -ms-transform-origin: right top 0;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
    -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -ms-transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  width: 100%;
}
#rt_header
  nav
  #navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li.active
  a {
  background: none;
  border-bottom: 2px solid;
  border-color: #ffffff;
}
@media only screen and (max-width: 767px) {
  #rt_header
    nav
    #navigation
    .navbar.navbar-default
    .navbar-collapse
    ul.nav.navbar-nav
    li.active
    a {
    border-bottom: none;
  }
}

/* =========================== */
/*         End Header          */
/* =========================== */
/* ====================== */
/*         Banner         */
/* ====================== */
#rt_banner,
#rt_banner.product {
  overflow: hidden;
  background-size: cover !important;
  position: relative;
  /* height: 850px; */
  width: 100%;
}
#rt_banner .bg_home,
#rt_banner.product .bg_home {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9999;
}
@media only screen and (max-width: 991px) {
  #rt_banner,
  #rt_banner.product {
    height: 100% !important;
    position: relative;
  }
}
#rt_banner .overlay,
#rt_banner.product .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
#rt_banner .banner_caption_text,
#rt_banner.product .banner_caption_text {
  margin-top: 190px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #rt_banner .banner_caption_text,
  #rt_banner.product .banner_caption_text {
    margin-top: 220px;
  }
}
#rt_banner .banner_caption_text h1,
#rt_banner.product .banner_caption_text h1 {
  color: #ffffff;
  font-size: 54px;
  line-height: 80px;
  margin-bottom: 20px;
  padding: 0;
  position: relative;
}
@media only screen and (max-width: 640px) {
  #rt_banner .banner_caption_text h1,
  #rt_banner.product .banner_caption_text h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px;
    position: relative;
  }
}
@media only screen and (max-width: 480px) {
  #rt_banner .banner_caption_text h1,
  #rt_banner.product .banner_caption_text h1 {
    font-size: 26px;
  }
}
#rt_banner .banner_caption_text p,
#rt_banner.product .banner_caption_text p {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 45px;
  line-height: 36px;
}
@media only screen and (max-width: 640px) {
  #rt_banner .banner_caption_text p,
  #rt_banner.product .banner_caption_text p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}
#rt_banner .banner_caption_text .btn-app-store,
#rt_banner.product .banner_caption_text .btn-app-store {
  background: #ffffff;
  color: #000000;
  height: 60px;
  padding-left: 75px;
  padding-right: 40px;
  position: relative;
  margin-right: 20px;
}
@media only screen and (max-width: 767px) {
  #rt_banner .banner_caption_text .btn-app-store,
  #rt_banner.product .banner_caption_text .btn-app-store {
    margin-bottom: 15px;
  }
}
#rt_banner .banner_caption_text .btn-app-store:hover,
#rt_banner.product .banner_caption_text .btn-app-store:hover {
  background: #000000;
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
#rt_banner .banner_caption_text .btn-app-store .fa-google-play,
#rt_banner.product .banner_caption_text .btn-app-store .fa-google-play {
  color: #ffffff;
}
#rt_banner .banner_caption_text .btn-app-store i,
#rt_banner.product .banner_caption_text .btn-app-store i {
  font-size: 30px;
  left: 25px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
}
#rt_banner .banner_caption_text .btn-app-store .small,
#rt_banner.product .banner_caption_text .btn-app-store .small {
  display: block;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 2px;
  margin-top: 5px;
}
#rt_banner .banner_caption_text .btn-app-store .big,
#rt_banner.product .banner_caption_text .btn-app-store .big {
  display: block;
  text-align: left;
  font-size: 21px;
  line-height: 21px;
}
#rt_banner .banner_caption_text .google_play,
#rt_banner.product .banner_caption_text .google_play {
  background: #a4c639;
  color: #ffffff;
}
#rt_banner .banner_caption_text .google_play:hover,
#rt_banner.product .banner_caption_text .google_play:hover {
  background: #ffffff;
  color: #a4c639;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
#rt_banner .banner_caption_text .google_play i,
#rt_banner.product .banner_caption_text .google_play i {
  font-size: 35px;
}
#rt_banner .banner_caption_img,
#rt_banner.product .banner_caption_img {
  margin-top: 150px;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  #rt_banner .banner_caption_img,
  #rt_banner.product .banner_caption_img {
    margin-bottom: 55px;
    margin-top: 15px;
  }
}
#rt_banner .banner_caption_img img,
#rt_banner.product .banner_caption_img img {
  width: 120%;
}
@media only screen and (max-width: 480px) {
  #rt_banner .banner_caption_img img,
  #rt_banner.product .banner_caption_img img {
    width: 100%;
  }
}

#rt_banner.vr_banner {
  height: 775px;
}
#rt_banner.vr_banner .banner_shape,
#rt_banner.vr_banner .banner_shape_2 {
  position: absolute;
  z-index: -1;
  height: 850px;
  width: 141%;
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  right: -24%;
  top: -80%;
  -webkit-transform: rotate(43deg);
  -ms-transform: rotate(43deg);
  transform: rotate(43deg);
  border-radius: 130px;
}
@media only screen and (max-width: 991px) {
  #rt_banner.vr_banner .banner_shape,
  #rt_banner.vr_banner .banner_shape_2 {
    top: -395px;
  }
}
@media only screen and (max-width: 767px) {
  #rt_banner.vr_banner .banner_shape,
  #rt_banner.vr_banner .banner_shape_2 {
    height: 1010px;
    top: -315px;
    width: 220%;
  }
}
#rt_banner.vr_banner .banner_shape_2 {
  top: -82%;
  opacity: 0.1;
  width: 142%;
  transform: rotate(47deg);
}
#rt_banner.vr_banner .banner_caption_text {
  margin-top: 260px;
}
#rt_banner.vr_banner .banner_caption_text h1,
#rt_banner.vr_banner .banner_caption_text p {
  color: #444;
}
@media only screen and (max-width: 767px) {
  #rt_banner.vr_banner .banner_caption_text h1,
  #rt_banner.vr_banner .banner_caption_text p {
    color: #fff;
  }
}
#rt_banner.vr_banner .btn-app-store {
  background: #5433ff !important;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1) !important;
  border: none;
}
#rt_banner.vr_banner .btn-app-store:hover {
  background: #444 !important;
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
#rt_banner.vr_banner .btn-app-store .fa-google-play {
  color: #ffffff;
}
#rt_banner.vr_banner .btn-app-store i {
  font-size: 30px;
  left: 25px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  color: #fff;
}
#rt_banner.vr_banner .btn-app-store .small {
  display: block;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 2px;
  margin-top: 5px;
  color: #fff;
}
#rt_banner.vr_banner .btn-app-store .big {
  display: block;
  text-align: left;
  font-size: 21px;
  line-height: 21px;
  color: #fff;
}
#rt_banner.vr_banner .banner_caption_img {
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: animateUpDown;
  animation-name: animateUpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes animateUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes animateUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
#rt_banner.rt_saas_app {
  overflow: visible;
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}
@media only screen and (max-width: 991px) {
  #rt_banner.rt_saas_app {
    padding-bottom: 50px;
  }
}
#rt_banner.rt_saas_app .white-svg,
#rt_banner.rt_saas_app .gradient-svg,
#rt_banner.rt_saas_app .grey-svg {
  z-index: 1 !important;
}
#rt_banner.rt_saas_app .banner_caption_text .btn-app-store i {
  font-size: 30px;
  left: 2px;
  position: relative;
  top: 1px;
  transform: initial;
}
#rt_banner.rt_saas_app .btn-app-store .small {
  text-align: left;
}
#rt_banner.rt_saas_app .rt_btn_icon {
  width: 40px;
  height: 40px;
  font-size: 30px;
  left: 13px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background: #000;
  color: #fff;
}
#rt_banner.rt_saas_app .dashboard_img {
  margin-top: 40px;
}
#rt_banner.rt_saas_app .dashboard_img img {
  width: 100%;
  position: relative;
  z-index: 2;
}

/* ========================== */
/*         End Banner         */
/* ========================== */
/* =============================== */
/*             Main Slider         */
/* =============================== */
.item-slider {
  width: 100%;
  height: 100%;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.item-slider:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(33, 42, 73, 0.4);
}

.carousel {
  height: 700px;
}
.carousel .carousel-caption {
  bottom: 20%;
}
@media only screen and (max-width: 960px) {
  .carousel .carousel-caption {
    bottom: 90px;
  }
}
@media only screen and (max-width: 500px) {
  .carousel .carousel-caption {
    bottom: 160px;
  }
}
@media only screen and (max-width: 450px) {
  .carousel .carousel-caption {
    bottom: 175px;
  }
}
@media only screen and (max-width: 360px) {
  .carousel .carousel-caption {
    left: 10%;
    right: 10%;
  }
}
.carousel .carousel-caption .site-heading {
  color: #ffffff;
}
@media only screen and (max-width: 860px) {
  .carousel .carousel-caption .site-heading {
    font-size: 36px;
  }
}
@media only screen and (max-width: 500px) {
  .carousel .carousel-caption .site-heading {
    font-size: 28px;
    line-height: 41px;
  }
}
@media only screen and (max-width: 450px) {
  .carousel .carousel-caption .site-heading {
    font-size: 26px;
  }
}
@media only screen and (max-width: 420px) {
  .carousel .carousel-caption .site-heading {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 25px;
  }
}
.carousel .carousel-caption .site-heading .text-highlight {
  color: #00d5c3;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.item,
.active,
.carousel-inner {
  height: 100%;
}

.promo-description {
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 50px;
  color: #fff;
  font-size: 18px;
}
@media only screen and (max-width: 500px) {
  .promo-description {
    font-size: 16px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 420px) {
  .promo-description {
    font-size: 14px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 330px) {
  .promo-description {
    font-size: 12px;
  }
}

.carousel_button_list li {
  margin-bottom: 17px;
}
.carousel_button_list li .fa_btn_green {
  background-color: #00d5c3;
  color: #ffffff;
  text-shadow: none;
}
.carousel_button_list li .fa_btn_green:hover {
  background-color: #ffffff;
  color: #00d5c3;
  border-color: #ffffff;
  transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.carousel_button_list li:before {
  display: none;
}
.carousel_button_list li a img {
  width: 70px;
}
.carousel_button_list li a img:hover {
  opacity: 0.9;
}

.carousel-control {
  position: absolute;
  top: 40%;
  z-index: 5;
  display: inline-block;
  box-shadow: none;
  -ms-box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  font-size: 58px;
}
.carousel-control img {
  height: 175px;
}

.carousel-control,
.carousel-control.right {
  background-image: none;
  color: #fff;
}

.carousel-control.left {
  background-image: none;
  color: #fff;
}

.carousel-indicators {
  bottom: 8%;
}
@media only screen and (max-width: 860px) {
  .carousel-indicators {
    bottom: 3%;
  }
}
.carousel-indicators li {
  text-indent: -999px;
  cursor: pointer;
  border: 1px solid #00d5c3;
  border-radius: 5px;
  display: inline-block;
  height: 15px;
  margin-right: 10px !important;
  width: 5px;
  padding: 0;
}
.carousel-indicators li:before {
  display: none;
}
.carousel-indicators .active {
  background: #00d5c3 none repeat scroll 0 0;
  border-radius: 5px;
  height: 20px;
  transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  width: 5px;
}

.header-subscribe-form {
  max-width: 570px;
  margin: 0 auto;
}
@media only screen and (max-width: 480px) {
  .header-subscribe-form .input-group {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .header-subscribe-form .input-group input[type="text"] {
    border-radius: 5px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .header-subscribe-form .input-group button.btn-primary {
    border-radius: 5px;
  }
}
.header-subscribe-form input[type="email"] {
  border: medium none;
  box-shadow: none;
  height: 55px;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  font-size: 16px;
  padding-left: 26px;
  background-color: #fafafa;
  color: #1a1a1a;
  border: none;
  border: 3px solid;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color, border-color;
  transition-property: color, background-color, border-color;
}
.header-subscribe-form input[type="text"] {
  border: medium none;
  box-shadow: none;
  height: 55px;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  font-size: 16px;
  padding-left: 26px;
  background-color: #f3f3f4;
  color: #1a1a1a;
  border: none;
  border: 3px solid #00d5c3;
}
.header-subscribe-form button {
  font-size: 19px;
  height: 55px;
  border-radius: 28px;
  background-color: #00d5c3;
  border-color: #00d5c3;
  padding: 11px 40px;
}
@media only screen and (max-width: 640px) {
  .header-subscribe-form button {
    padding: 11px 26px;
    font-size: 16px;
  }
}
.header-subscribe-form button:hover {
  background-color: #05b4a5;
  border-color: #05b4a5;
  transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

#rt_services {
  padding: 100px 0 120px 0;
  background: #fff;
}
#rt_services .box {
  padding: 40px 30px;
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
  -moz-box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
  -o-box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
  -ms-box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
  box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
}
@media only screen and (max-width: 991px) {
  #rt_services .box {
    margin-bottom: 30px;
  }
}
#rt_services .box:before {
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  bottom: 0;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  width: 100%;
}
#rt_services .box:hover {
  -webkit-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -moz-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -o-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -ms-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}
#rt_services .box:hover:before {
  background: #5433ff;
  background: linear-gradient(to right, #20bdff 0%, #5433ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#20BDFF', endColorstr='#5433FF', GradientType=1);
}
#rt_services .box .icon {
  text-align: center;
  margin: 0 auto;
}
#rt_services .box .icon i {
  font-size: 54px;
  margin-bottom: 25px;
  color: #212529;
}
#rt_services .box h3 {
  margin-bottom: 23px;
  margin-top: 0;
  color: #212529;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  #rt_services .box h3 {
    margin-bottom: 13px;
    line-height: 35px;
    font-size: 20px;
  }
}
#rt_services .box p {
  margin-bottom: 0;
}

#rt_services_product {
  position: relative;
  padding: 100px 0 120px 0;
}
#rt_services_product .box {
  padding: 40px 30px;
  position: relative;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
}
#rt_services_product .box:hover {
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}
#rt_services_product .box:hover h3,
#rt_services_product .box:hover p {
  color: #fff;
}
#rt_services_product .box:hover .icon {
  -webkit-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -moz-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -o-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  -ms-box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  box-shadow: 0 5px 25px 0 rgba(5, 5, 5, 0.2);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  background: #fff;
}
#rt_services_product .box:hover .icon i {
  color: #5433ff !important;
}
@media only screen and (max-width: 991px) {
  #rt_services_product .box {
    margin-bottom: 30px;
  }
}
#rt_services_product .box .icon {
  text-align: center;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  border-radius: 50%;
  margin-bottom: 25px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
  -moz-box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
  -o-box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
  -ms-box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
  box-shadow: 0 1px 5px 0 rgba(5, 5, 5, 0.2);
}
#rt_services_product .box .icon i {
  font-size: 36px;
  color: #ffffff;
  line-height: 100px;
}
#rt_services_product .box h3 {
  margin-bottom: 23px;
  margin-top: 0;
  color: #565656;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  #rt_services_product .box h3 {
    margin-bottom: 13px;
    line-height: 35px;
    font-size: 20px;
  }
}
#rt_services_product .box p {
  margin-bottom: 0;
}

#rt_services.rt_saas_services {
  padding-top: 190px;
  background: transparent;
}
@media only screen and (max-width: 991px) {
  #rt_services.rt_saas_services {
    padding: 100px 0;
  }
}
#rt_services.rt_saas_services .rt_btn_color {
  font-weight: 400;
}
#rt_services.rt_saas_services .sassrt_servcs {
  position: relative;
}
@media only screen and (max-width: 991px) {
  #rt_services.rt_saas_services .heading_servic_sec {
    margin-bottom: 50px;
  }
}
#rt_services.rt_saas_services .heading_servic_sec h3 {
  font-weight: 700;
  line-height: 48px;
  font-size: 34px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  #rt_services.rt_saas_services .heading_servic_sec h3 {
    font-size: 24px;
    line-height: 38px;
  }
}
#rt_services.rt_saas_services .box {
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  color: #fff;
  margin: 20px 15px;
}
@media only screen and (max-width: 767px) {
  #rt_services.rt_saas_services .box {
    padding: 40px 10px;
  }
}
#rt_services.rt_saas_services .box h3,
#rt_services.rt_saas_services .box p,
#rt_services.rt_saas_services .box i {
  color: #fff;
}
#rt_services.rt_saas_services .owl-nav {
  display: block !important;
  position: absolute;
  top: -46px;
  right: 0px;
  color: #666;
}
#rt_services.rt_saas_services .owl-nav .owl-next,
#rt_services.rt_saas_services .owl-nav .owl-prev {
  display: inline-block;
  background: transparent !important;
  margin: 0;
  padding: 0;
}
#rt_services.rt_saas_services .owl-nav i {
  font-size: 40px;
  margin-right: 15px;
  color: #666;
}

#rt_welcome {
  /* padding: 120px 0; */
  position: relative;
}
#rt_welcome .rt_about_us {
  margin-bottom: 30px;
  width: 100%;
  float: left;
}
#rt_welcome .rt_service_box {
  padding: 10px;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 20px;
  text-align: left;
  border-radius: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #rt_welcome .col-sm-6 {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #rt_welcome .about_services {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 768px) {
  #rt_welcome .about_services {
    margin-bottom: 40px;
  }
}
#rt_welcome .about_services .col-sm-6 {
  padding-left: 0;
}
@media only screen and (max-width: 991px) {
  #rt_welcome .about_services h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 480px) {
  #rt_welcome .about_services h2 {
    font-size: 18px;
  }
}
#rt_welcome .about_services h2 span {
  padding-top: 0;
}
#rt_welcome .about_services i {
  font-size: 46px;
  margin-bottom: 24px;
  background: -webkit-linear-gradient(#5433ff, #20bdff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  line-height: 1;
  margin-top: 15px;
}
#rt_welcome .about_services h5 {
  margin-bottom: 15px;
  margin-top: 0;
}
#rt_welcome .mt_about_img {
  text-align: center;
}
#rt_welcome .mt_about_img img {
  max-width: 100%;
  margin-top: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #rt_welcome .mt_about_img img {
    margin-top: 50px;
  }
}

/* About US CSS */

/* ===================================== */
#rt_screenshots,
#rt_drone_screenshots {
  padding: 120px 0;
  background: #5433ff;
  background: linear-gradient(to right, #5433ff 0%, #20bdff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
}
#rt_screenshots .owl-carousel .owl-item,
#rt_drone_screenshots .owl-carousel .owl-item {
  position: relative;
  background-color: transparent;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAkCAMAAAA5HAOUAAAAllBMVEUzMzP///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzNnZ2czMzMzMzNeXl5dXV0zMzNbW1tVVVUzMzNNTU309PTv7+/19fXy8vLw8PDu7u7r6+v///+Ssb7mAAAAMXRSTlMAAAECAwQFCAoMDQ4PEBETFBYXGRscICEkJykrLDA8PT9AQ0tNUFJfYn3L0Nre4ePnwy0YnQAAAM9JREFUOMvt08kOgjAQgOG2dKRs7ohsbqCgrH3/l5OiJvVi5mK8+N86+ZKSthCKifzVVxQZYgYH4IwRLcbUzHjMlGJgWq4jJu9qIhzXMoE9FQMx9zf+THBdcTEbhnMxMqrQMjzUN98BtX4Fjn+rD+FyZHRAqyRvZBWvPUvLW8eVbPJkpRg1zEV8aWVXHNNwqxWmx6KT7SVemAah3A6yVvbX8nza77T2p3N57WWbBTYnFKZRIz/URFPAKtyOuK/HnQTuVJE3hLxtzMv5/2m/UnfjeSwjIvb58QAAAABJRU5ErkJggg==),
    move;
  -moz-cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAkCAMAAAA5HAOUAAAAllBMVEUzMzP///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzNnZ2czMzMzMzNeXl5dXV0zMzNbW1tVVVUzMzNNTU309PTv7+/19fXy8vLw8PDu7u7r6+v///+Ssb7mAAAAMXRSTlMAAAECAwQFCAoMDQ4PEBETFBYXGRscICEkJykrLDA8PT9AQ0tNUFJfYn3L0Nre4ePnwy0YnQAAAM9JREFUOMvt08kOgjAQgOG2dKRs7ohsbqCgrH3/l5OiJvVi5mK8+N86+ZKSthCKifzVVxQZYgYH4IwRLcbUzHjMlGJgWq4jJu9qIhzXMoE9FQMx9zf+THBdcTEbhnMxMqrQMjzUN98BtX4Fjn+rD+FyZHRAqyRvZBWvPUvLW8eVbPJkpRg1zEV8aWVXHNNwqxWmx6KT7SVemAah3A6yVvbX8nza77T2p3N57WWbBTYnFKZRIz/URFPAKtyOuK/HnQTuVJE3hLxtzMv5/2m/UnfjeSwjIvb58QAAAABJRU5ErkJggg==),
    move;
  -webkit-cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAkCAMAAAA5HAOUAAAAllBMVEUzMzP///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzNnZ2czMzMzMzNeXl5dXV0zMzNbW1tVVVUzMzNNTU309PTv7+/19fXy8vLw8PDu7u7r6+v///+Ssb7mAAAAMXRSTlMAAAECAwQFCAoMDQ4PEBETFBYXGRscICEkJykrLDA8PT9AQ0tNUFJfYn3L0Nre4ePnwy0YnQAAAM9JREFUOMvt08kOgjAQgOG2dKRs7ohsbqCgrH3/l5OiJvVi5mK8+N86+ZKSthCKifzVVxQZYgYH4IwRLcbUzHjMlGJgWq4jJu9qIhzXMoE9FQMx9zf+THBdcTEbhnMxMqrQMjzUN98BtX4Fjn+rD+FyZHRAqyRvZBWvPUvLW8eVbPJkpRg1zEV8aWVXHNNwqxWmx6KT7SVemAah3A6yVvbX8nza77T2p3N57WWbBTYnFKZRIz/URFPAKtyOuK/HnQTuVJE3hLxtzMv5/2m/UnfjeSwjIvb58QAAAABJRU5ErkJggg==),
    move;
  -o-cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAkCAMAAAA5HAOUAAAAllBMVEUzMzP///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzNnZ2czMzMzMzNeXl5dXV0zMzNbW1tVVVUzMzNNTU309PTv7+/19fXy8vLw8PDu7u7r6+v///+Ssb7mAAAAMXRSTlMAAAECAwQFCAoMDQ4PEBETFBYXGRscICEkJykrLDA8PT9AQ0tNUFJfYn3L0Nre4ePnwy0YnQAAAM9JREFUOMvt08kOgjAQgOG2dKRs7ohsbqCgrH3/l5OiJvVi5mK8+N86+ZKSthCKifzVVxQZYgYH4IwRLcbUzHjMlGJgWq4jJu9qIhzXMoE9FQMx9zf+THBdcTEbhnMxMqrQMjzUN98BtX4Fjn+rD+FyZHRAqyRvZBWvPUvLW8eVbPJkpRg1zEV8aWVXHNNwqxWmx6KT7SVemAah3A6yVvbX8nza77T2p3N57WWbBTYnFKZRIz/URFPAKtyOuK/HnQTuVJE3hLxtzMv5/2m/UnfjeSwjIvb58QAAAABJRU5ErkJggg==),
    move;
  -ms-cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAkCAMAAAA5HAOUAAAAllBMVEUzMzP///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzNnZ2czMzMzMzNeXl5dXV0zMzNbW1tVVVUzMzNNTU309PTv7+/19fXy8vLw8PDu7u7r6+v///+Ssb7mAAAAMXRSTlMAAAECAwQFCAoMDQ4PEBETFBYXGRscICEkJykrLDA8PT9AQ0tNUFJfYn3L0Nre4ePnwy0YnQAAAM9JREFUOMvt08kOgjAQgOG2dKRs7ohsbqCgrH3/l5OiJvVi5mK8+N86+ZKSthCKifzVVxQZYgYH4IwRLcbUzHjMlGJgWq4jJu9qIhzXMoE9FQMx9zf+THBdcTEbhnMxMqrQMjzUN98BtX4Fjn+rD+FyZHRAqyRvZBWvPUvLW8eVbPJkpRg1zEV8aWVXHNNwqxWmx6KT7SVemAah3A6yVvbX8nza77T2p3N57WWbBTYnFKZRIz/URFPAKtyOuK/HnQTuVJE3hLxtzMv5/2m/UnfjeSwjIvb58QAAAABJRU5ErkJggg==),
    move;
  overflow: hidden;
}
#rt_screenshots .owl-carousel img,
#rt_drone_screenshots .owl-carousel img {
  height: auto;
  opacity: 0.15;
  -moz-transform: scale(0.85);
  -webkit-transform: scale(0.85);
  -o-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  width: 100%;
}
#rt_screenshots .owl-item.active.center img,
#rt_drone_screenshots .owl-item.active.center img {
  display: block;
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.6);
}
#rt_screenshots .item,
#rt_screenshots .item img,
#rt_drone_screenshots .item,
#rt_drone_screenshots .item img {
  transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
}
#rt_screenshots .owl-carousel .owl-item,
#rt_screenshots .owl-carousel .owl-wrapper,
#rt_drone_screenshots .owl-carousel .owl-item,
#rt_drone_screenshots .owl-carousel .owl-wrapper {
  backface-visibility: hidden;
  transform: initial;
}
#rt_screenshots .owl-nav,
#rt_drone_screenshots .owl-nav {
  display: none;
}

/* ========================================= */
/*           End ScreenShots Section         */
/* ========================================= */
/* ====================== */
/*         	Team          */
/* ====================== */

/*  */
.newsletter_form {
  position: relative;
  width: 100%;
  display: block;
}
.newsletter_form p {
  margin-bottom: 0;
}
.newsletter_form input {
  padding-top: 15px;
  padding-bottom: 15px;
}
.newsletter_form .animated_btn {
  position: absolute;
  top: 89%;
  height: 100%;
  right: 0px;
  z-index: 1;
  margin-bottom: 0;
  transform: translate(0, -90%);
}
.newsletter_form .animated_btn span {
  padding: 11px;
}
.newsletter_form .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 18px 170px 18px 10px;
  font-size: 12px;
  color: #000;
  background-color: transparent;
  height: auto;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-bottom: 10px;
}

.animated_btn {
  position: relative;
  display: inline-flex;
  text-decoration: none;
  color: #fff;
  background: #cd7f01;
  background: linear-gradient(to right, #a74505 0%, #cd7f01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5433FF', endColorstr='#20BDFF', GradientType=1);
  padding-left: 2rem;
  overflow: hidden;
  z-index: 1;
  align-items: center;
  box-shadow: 0px 3px 4px -4px rgba(0, 0, 0, 0.75);
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}
@media only screen and (max-width: 480px) {
  .animated_btn {
    font-size: 12px;
    letter-spacing: initial;
  }
}
.animated_btn:hover {
  color: #fff;
}
.animated_btn:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  color: #fff;
}
.animated_btn:hover i {
  -webkit-animation: moveArrow 750ms;
  animation: moveArrow 750ms;
}
.animated_btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  width: 100%;
  height: 100%;
  background-color: #cd7f01;
  z-index: -1;
  transition: -webkit-transform 750ms;
  transition: transform 750ms, -webkit-transform 750ms;
  color: #fff;
}
.animated_btn span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
  padding: 18px;
  overflow: hidden;
  background-color: #cd7f01;
}
.animated_btn i {
  width: 100%;
  height: auto;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
}

@-webkit-keyframes moveArrow {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  49% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  50% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes moveArrow {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  49% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  50% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

/*  */
#mt_footer {
  padding-top: 90px;
  background: #000000;
}
#mt_footer h2 {
  color: #fff;
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 40px;
  font-weight: 500;
}
#mt_footer .logo img {
  width: 150px;
}
@media only screen and (max-width: 991px) {
  #mt_footer .col-md-4 {
    clear: both;
  }
}
@media only screen and (max-width: 991px) {
  #mt_footer .follow_us {
    margin-bottom: 30px;
  }
}
#mt_footer .follow_us a {
  display: inline-block;
  margin-bottom: 30px;
}
#mt_footer .follow_us p,
#mt_footer .follow_us h4 {
  font-size: 14px;
  color: #fff;
}
#mt_footer .follow_us h4 {
  margin-bottom: 5px;
}
#mt_footer .follow_us .social_icons li {
  display: inline-block;
  line-height: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 15px;
}
#mt_footer .follow_us .social_icons li:before {
  display: none;
}
#mt_footer .follow_us .social_icons li a {
  color: #fff;
  margin-bottom: 0;
}
#mt_footer .follow_us .social_icons li a:hover {
  color: #20bdff;
}
#mt_footer .mt_contact_ftr {
  float: left;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  #mt_footer .mt_contact_ftr {
    margin-bottom: 30px;
  }
}
#mt_footer .mt_contact_ftr li {
  padding-left: 0;
  /* width: 100%; */
  float: left;
}
#mt_footer .mt_contact_ftr li:before {
  display: none;
}
#mt_footer .mt_contact_ftr li span {
  display: inline-block;
  float: left;
  margin-right: 0;
  font-size: 28px;
  color: #ffffff;
}
#mt_footer .mt_contact_ftr li a {
  display: inline-block;
  float: left;
  /* margin-left: 20px; */

  color: #ffffff;
  font-size: 14px;
}
#mt_footer .mt_contact_ftr li a svg {
  height: 24px;
  width: 25px;
  color: #fff;
}
#mt_footer .mt_contact_ftr li a svg:hover {
  color: rgba(167, 69, 5, 1);
}
#mt_footer .mt_contact_ftr li a:hover {
  color: #20bdff;
}
#mt_footer .mt_post_ftr {
  clear: both;
}
#mt_footer .mt_post_ftr li {
  padding-left: 0;
}
@media only screen and (max-width: 991px) {
  #mt_footer .mt_post_ftr li {
    clear: both;
  }
}
#mt_footer .mt_post_ftr li:before {
  display: none;
}
#mt_footer .mt_post_ftr li img {
  border-radius: 10px;
  float: left;
  margin-right: 15px;
  width: 20%;
}
@media only screen and (max-width: 991px) {
  #mt_footer .mt_post_ftr li img {
    margin-bottom: 15px;
    width: 10%;
  }
}
@media only screen and (max-width: 480px) {
  #mt_footer .mt_post_ftr li img {
    width: 25%;
  }
}
#mt_footer .mt_post_ftr li h4 {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 3px;
  margin-top: 0;
}
#mt_footer .mt_post_ftr li h4 a {
  color: #ffffff;
}
#mt_footer .mt_post_ftr li p {
  color: #eceaea;
  font-size: 12px;
}
#mt_footer .mt_footer_copy {
  border-top: 1px solid;
  margin-top: 90px;
  padding-top: 22px;
}
#mt_footer .mt_footer_copy .copy_txt p {
  font-size: 12px;
  color: #eceaea;
}
@media only screen and (max-width: 991px) {
  #mt_footer .mt_footer_copy .copy_txt p {
    text-align: center;
  }
}
#mt_footer .mt_footer_copy .made_txt p {
  color: #eceaea;
  font-size: 12px;
  text-align: right;
}
@media only screen and (max-width: 991px) {
  #mt_footer .mt_footer_copy .made_txt p {
    text-align: center;
  }
}
#mt_footer .mt_footer_copy .made_txt i {
  color: #e91e63;
  margin-left: 5px;
}

/* footer Css End */

.naas:hover {
  background: linear-gradient(
    90.4deg,
    #5335ff 0.31%,
    #22baff 97.25%
  ) !important;
}
.naas:hover img {
  background-color: #fff;
  border-radius: 50%;
}
.naas:hover p {
  color: #fff;
}
.naas:hover h4 {
  color: #fff;
}

.naas-bt:hover {
  border-bottom: 5px solid #5335ff !important;
}

.raas-card {
  border: 1px solid rgba(167, 69, 5, 1);
  border-radius: 20px;
}

.rass-bt-box {
}

.raas-card > div > p {
  margin-bottom: 20px !important;
}

.proto-icons > img {
  aspect-ratio: 4/2;
  object-fit: contain;
}

.proto-icons {
  padding: 30px 10px !important;
  border: 1px solid #f2f2f2;
  margin: 10px;
  border-radius: 20px;
}

.proto-icons:hover {
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
}

.flex {
  display: flex;
  align-items: center;
}

.gap-2 {
  gap: 10px;
}

.naas-slide {
  padding: 20px;
  border-radius: 20px;
  width: 100%;
}

.raas-card-box {
  border: 1px solid rgba(167, 69, 5, 1);
  margin-top: 10px;
}

.choose-us-container {
  background-image: url("/public/images/choose-us.webp");
  padding: 30px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.choose-us-position {
  position: absolute;
  margin: 0 auto;
  width: 100%;
}

.choose-us-box {
  background-color: #fff;
  border: 1px solid #a74505;
  text-align: center;
}
.choose-us-boxes{
  background-color:linear-gradient(180deg, #A74505 0%, #CD7F01 100%);
    position: relative;
  border: 1px solid #a74505;
  color: #000;
  text-align: center;
}

.choose-us-rt-box {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.choose-us-rt-box > img {
  margin-bottom: 10px;
}

.circle-box {
  max-width: 308px;
  max-height: 307px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  margin: 0 auto;
}

.circle-box > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.relativeBox {
  position: relative;
}

#overlay {
  position: absolute; /* Sit on top of the page content */
  /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  align-items: center;
  justify-content: center;
}

#overlay > * {
  color: #ffff;
}

