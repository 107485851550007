.slick-track {
  display: flex;
  gap: 20px;
}
.outer-box {
  padding: 10px;
  /* border: 1px solid  #5334ff;
  border-radius: 10px; */
  /* border-image: ; */
}


.slick-cont {
  padding: 0 40px;
}

@media (max-width: 768px) {
  .slick-cont {
    padding: 0px;
  }
}