.footer-contaner {
    padding: 0 100px;
}

@media (max-width: 899px) {
    .footer-contaner {
        padding: 5px 10px;
    }
}

@media (min-width: 899px) and (max-width: 1200px) {
    .footer-contaner {
        padding: 5px 27px;
    }
}
